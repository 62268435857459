<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>User Activity</template>
			<v-form v-model="valid" ref="form">
			<v-row>
				<v-col cols="12" lg="4">
					<v-select v-model="report_params.corporate_id" :items="corporates" item-text="name" item-value="id" label="Corporate" clearable :rules="requiredRules"></v-select>
				</v-col>
				<v-col cols="12" lg="4">
					<v-text-field type="date" v-model="report_params.start_date" label="Start date" clearable :rules="requiredRules"></v-text-field>
				</v-col>
				<v-col cols="12" lg="4">
					<v-text-field type="date" v-model="report_params.end_date" label="End date" clearable :rules="requiredRules"></v-text-field>
				</v-col>
				<v-col cols="12" lg="4">
					<v-checkbox type="date" v-model="report_params.only_courses" label="Only courses"></v-checkbox>
				</v-col>
				<v-col cols="12" lg="4"></v-col>
				<v-col cols="12" lg="4" class="d-flex justify-end">
					<v-btn color="success" elevation="2" @click="getData" :loading="loading">
						Get Data
					</v-btn>
				</v-col>
			</v-row>
			</v-form>

			<v-data-table :headers="headers" :items="reportData.results" item-key="id" :search="search">
				<template v-slot:item.passed="{ item }">
					{{ item.passed ? 'Passed' : 'Failed' }}
				</template>

				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="reportData.results" filename="Benchmark Results"/>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>

import {sync} from "vuex-pathify";

export default {
	name: "UserActivity",
	computed: {
		corporates: sync('corporates/corporates'),
	},
	mounted() {
		this.init()
	},
	data: () => ({
		valid: false,
		requiredRules: [
			v => !!v || 'Required',
		],
		report_params: {
			corporate_id: null,
			start_date: null,
			end_date: null,
			only_courses: false
		},
		search: '',
		loading: false,
		reportData: {
			count: 0,
			results: [
				{
					topic_name: '',
					industry: '',
					seniority: '',
					key_focus: '',
					avg_score: 0
				}
			]
		},
		headers: [
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'Resource',
				align: 'start',
				sortable: true,
				value: 'resource_title',
			},
			{
				text: 'Resource Type',
				align: 'start',
				sortable: true,
				value: 'resource_type',
			},
			{
				text: 'Passed',
				align: 'start',
				sortable: true,
				value: 'passed',
			},
			{
				text: 'Sessions',
				align: 'start',
				sortable: true,
				value: 'sessions',
			},
			{
				text: 'Score',
				align: 'end',
				sortable: true,
				value: 'score',
			},
		],
	}),
	methods: {
		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		getData() {

			if(this.$refs.form.validate()){
				this.loading = true;
				this.$api.reports.userActivity({report_params: this.report_params})
					.then(response => this.reportData = response.data)
					.catch(error => console.log(error))
					.finally(() => this.loading = false)
			}
		}
	}
}
</script>

<style lang="scss">
table.pvtTable thead tr th, table.pvtTable tbody tr th {
	background: #eee;
}

.pvtUi {
	* {
		font-family: 'roboto', sans-serif;
	}

	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;

	tr td {
		border: 1px solid #333;

		&:first-child {
			width: 300px;
		}
	}
}
</style>